/**
* # Fetch
* (module_name: Fetch)
* The request library based on Fetch API
* ======================================
*/

import { GET, POST, PUT, PATCH, DELETE } from "./fetch"
import * as Fetch from "./fetch"

export {GET, POST, PUT, PATCH, DELETE}

export default Fetch
