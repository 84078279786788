import React from 'react'

function Welcome() {
  return (
    <div>
      <h3>Welcome to super admin.</h3>
    </div>
  )
}

export default Welcome
