export const REQUEST_FETCH_STATES = 'REQUEST_FETCH_STATES'
export const SUCCESS_FETCH_STATES = 'SUCCESS_FETCH_STATES'

export const REQUEST_FETCH_CITIES = 'REQUEST_FETCH_CITIES'
export const SUCCESS_FETCH_CITIES = 'SUCCESS_FETCH_CITIES'

export const REQUEST_FETCH_CITY_DETAILS = 'REQUEST_FETCH_CITY_DETAILS'
export const SUCCESS_FETCH_CITY_DETAILS = 'SUCCESS_FETCH_CITY_DETAILS'

export const REQUEST_CREATE_STATE = 'REQUEST_CREATE_STATE'
export const SUCCESS_CREATE_STATE = 'SUCCESS_CREATE_STATE'

export const REQUEST_CREATE_CITY = 'REQUEST_CREATE_CITY'
export const SUCCESS_CREATE_CITY = 'SUCCESS_CREATE_CITY'

export const REQUEST_UPDATE_STATE = 'REQUEST_UPDATE_STATE'
export const SUCCESS_UPDATE_STATE = 'SUCCESS_UPDATE_STATE'

export const REQUEST_FETCH_BRAND_MANAGERS = "REQUEST_FETCH_BRAND_MANAGERS"
export const SUCCESS_FETCH_BRAND_MANAGERS = "SUCCESS_FETCH_BRAND_MANAGERS"

export const REQUEST_CREATE_CASHBACK_SKU = "REQUEST_CREATE_CASHBACK_SKU"
export const SUCCESS_CREATE_CASHBACK_SKU = "SUCCESS_CREATE_CASHBACK_SKU"

export const REQUEST_SET_LOADING_ALL = "REQUEST_SET_LOADING_ALL"
export const SUCCESS_SET_LOADING_ALL = "SUCCESS_SET_LOADING_ALL"

export const REQUEST_UPDATE_CASHBACK_SKU = "REQUEST_UPDATE_CASHBACK_SKU"
export const SUCCESS_UPDATE_CASHBACK_SKU = "SUCCESS_UPDATE_CASHBACK_SKU"

export const REQUEST_CREATE_BRAND_MANAGER = "REQUEST_CREATE_BRAND_MANAGER"
export const SUCCESS_CREATE_BRAND_MANAGER = "SUCCESS_CREATE_BRAND_MANAGER"

export const REQUEST_UPDATE_BRAND_MANAGER = "REQUEST_UPDATE_BRAND_MANAGER"
export const SUCCESS_UPDATE_BRAND_MANAGER = "SUCCESS_UPDATE_BRAND_MANAGER"

export const REQUEST_FETCH_BRAND_MANAGER_LIST = "REQUEST_FETCH_BRAND_MANAGER_LIST"
export const SUCCESS_FETCH_BRAND_MANAGER_LIST = "SUCCESS_FETCH_BRAND_MANAGER_LIST"

export const REQUEST_FETCH_STATE_TIMINGS = 'REQUEST_FETCH_STATE_TIMINGS'
export const SUCCESS_FETCH_STATE_TIMINGS = 'SUCCESS_FETCH_STATE_TIMINGS'

export const REQUEST_CREATE_STATE_TIMING = "REQUEST_CREATE_STATE_TIMING"
export const SUCCESS_CREATE_STATE_TIMING = "SUCCESS_CREATE_STATE_TIMING"

export const REQUEST_UPDATE_STATE_TIMING = "REQUEST_UPDATE_STATE_TIMING"
export const SUCCESS_UPDATE_STATE_TIMING = "SUCCESS_UPDATE_STATE_TIMING"

export const REQUEST_FETCH_POSSESSION_LIMITS = "REQUEST_FETCH_POSSESSION_LIMITS"
export const SUCCESS_FETCH_POSSESSION_LIMITS = "SUCCESS_FETCH_POSSESSION_LIMITS"

export const REQUEST_CREATE_POSSESSION_LIMIT = "REQUEST_CREATE_POSSESSION_LIMIT"
export const SUCCESS_CREATE_POSSESSION_LIMIT = "SUCCESS_CREATE_POSSESSION_LIMIT"

export const REQUEST_UPDATE_POSSESSION_LIMIT = "REQUEST_UPDATE_POSSESSION_LIMIT"
export const SUCCESS_UPDATE_POSSESSION_LIMIT = "SUCCESS_UPDATE_POSSESSION_LIMIT"

export const REQUEST_UPDATE_CITY = 'REQUEST_UPDATE_CITY'
export const SUCCESS_UPDATE_CITY = 'SUCCESS_UPDATE_CITY'

export const REQUEST_UPDATE_GEOBOUNDARY = 'REQUEST_UPDATE_GEOBOUNDARY'
export const SUCCESS_UPDATE_GEOBOUNDARY = 'SUCCESS_UPDATE_GEOBOUNDARY'

export const REQUEST_FETCH_LOCALITIES = 'REQUEST_FETCH_LOCALITIES'
export const SUCCESS_FETCH_LOCALITIES = 'SUCCESS_FETCH_LOCALITIES'

export const REQUEST_CREATE_GEOLOCALITY = 'REQUEST_CREATE_GEOLOCALITY'
export const SUCCESS_CREATE_GEOLOCALITY = 'SUCCESS_CREATE_GEOLOCALITY'

export const REQUEST_UPDATE_GEOLOCALITY = 'REQUEST_UPDATE_GEOLOCALITY'
export const SUCCESS_UPDATE_GEOLOCALITY = 'SUCCESS_UPDATE_GEOLOCALITY'

export const REQUEST_SET_LOADING_STATE = 'REQUEST_SET_LOADING_STATE'
export const SUCCESS_SET_LOADING_STATE = 'SUCCESS_SET_LOADING_STATE'

export const REQUEST_FETCH_DELIVERERS = 'REQUEST_FETCH_DELIVERERS'
export const SUCCESS_FETCH_DELIVERERS = 'SUCCESS_FETCH_DELIVERERS'

export const REQUEST_FETCH_RETAILERS = 'REQUEST_FETCH_RETAILERS'
export const SUCCESS_FETCH_RETAILERS = 'SUCCESS_FETCH_RETAILERS'

export const REQUEST_UPLOAD_SEARCH_DATA = 'REQUEST_UPLOAD_SEARCH_DATA'
export const SUCCESS_UPLOAD_SEARCH_DATA = 'SUCCESS_UPLOAD_SEARCH_DATA'

export const REQUEST_FETCH_DP_RETAILER_MAP = 'REQUEST_FETCH_DP_RETAILER_MAP'
export const SUCCESS_FETCH_DP_RETAILER_MAP = 'SUCCESS_FETCH_DP_RETAILER_MAP'

export const REQUEST_FETCH_DP_LOCALITY_MAP = 'REQUEST_FETCH_DP_LOCALITY_MAP'
export const SUCCESS_FETCH_DP_LOCALITY_MAP = 'SUCCESS_FETCH_DP_LOCALITY_MAP'

export const REQUEST_UPDATE_DP_LOCALITY_MAP = 'REQUEST_UPDATE_DP_LOCALITY_MAP'
export const SUCCESS_UPDATE_DP_LOCALITY_MAP = 'SUCCESS_UPDATE_DP_LOCALITY_MAP'

export const REQUEST_UPDATE_DP_RETAILER_MAP = 'REQUEST_UPDATE_DP_RETAILER_MAP'
export const SUCCESS_UPDATE_DP_RETAILER_MAP = 'SUCCESS_UPDATE_DP_RETAILER_MAP'

export const REQUEST_DELETE_RETAILER_FROM_DP_MAP = 'REQUEST_DELETE_RETAILER_FROM_DP_MAP'
export const SUCCESS_DELETE_RETAILER_FROM_DP_MAP = 'SUCCESS_DELETE_RETAILER_FROM_DP_MAP'

export const REQUEST_DELETE_LOCALITY_FROM_DP_MAP = 'REQUEST_DELETE_LOCALITY_FROM_DP_MAP'
export const SUCCESS_DELETE_LOCALITY_FROM_DP_MAP = 'SUCCESS_DELETE_LOCALITY_FROM_DP_MAP'

export const REQUEST_FETCH_LOCALITY_RETAILERS_MAP = 'REQUEST_FETCH_LOCALITY_RETAILERS_MAP'
export const SUCCESS_FETCH_LOCALITY_RETAILERS_MAP = 'SUCCESS_FETCH_LOCALITY_RETAILERS_MAP'

export const REQUEST_DELETE_RETAILER_FROM_LOCALITY_MAP = 'REQUEST_DELETE_RETAILER_FROM_LOCALITY_MAP'
export const SUCCESS_DELETE_RETAILER_FROM_LOCALITY_MAP = 'SUCCESS_DELETE_RETAILER_FROM_LOCALITY_MAP'

export const REQUEST_FETCH_UNMAPPED_RETAILERS_TO_DP = 'REQUEST_FETCH_UNMAPPED_RETAILERS_TO_DP'
export const SUCCESS_FETCH_UNMAPPED_RETAILERS_TO_DP = 'SUCCESS_FETCH_UNMAPPED_RETAILERS_TO_DP'

export const REQUEST_FETCH_UNMAPPED_RETAILERS_TO_LOCALITY = 'REQUEST_FETCH_UNMAPPED_RETAILERS_TO_LOCALITY'
export const SUCCESS_FETCH_UNMAPPED_RETAILERS_TO_LOCALITY = 'SUCCESS_FETCH_UNMAPPED_RETAILERS_TO_LOCALITY'

export const REQUEST_ADD_RETAILER_TO_LOCALITY_MAP = 'REQUEST_ADD_RETAILER_TO_LOCALITY_MAP'
export const SUCCESS_ADD_RETAILER_TO_LOCALITY_MAP = 'SUCCESS_ADD_RETAILER_TO_LOCALITY_MAP'

export const REQUEST_MAP_RETAILER_TO_LOCALITY_AS_PRIME = 'REQUEST_MAP_RETAILER_TO_LOCALITY_AS_PRIME'
export const SUCCESS_MAP_RETAILER_TO_LOCALITY_AS_PRIME = 'SUCCESS_MAP_RETAILER_TO_LOCALITY_AS_PRIME'

export const REQUEST_UNMAP_RETAILER_TO_LOCALITY_AS_PRIME = 'REQUEST_UNMAP_RETAILER_TO_LOCALITY_AS_PRIME'
export const SUCCESS_UNMAP_RETAILER_TO_LOCALITY_AS_PRIME = 'SUCCESS_UNMAP_RETAILER_TO_LOCALITY_AS_PRIME'

export const REQUEST_FETCH_DP_BY_LOCALITY = 'REQUEST_FETCH_DP_BY_LOCALITY'
export const SUCCESS_FETCH_DP_BY_LOCALITY = 'SUCCESS_FETCH_DP_BY_LOCALITY'

export const REQUEST_ADD_DP_TO_LOCALITY_MAP = 'REQUEST_ADD_DP_TO_LOCALITY_MAP'
export const SUCCESS_ADD_DP_TO_LOCALITY_MAP = 'SUCCESS_ADD_DP_TO_LOCALITY_MAP'

export const REQUEST_DELETE_DP_FROM_LOCALITY_MAP = 'REQUEST_DELETE_DP_FROM_LOCALITY_MAP'
export const SUCCESS_DELETE_DP_FROM_LOCALITY_MAP = 'SUCCESS_DELETE_DP_FROM_LOCALITY_MAP'

export const REQUEST_FETCH_UNMAPPED_DP_TO_LOCALITY = 'REQUEST_FETCH_UNMAPPED_DP_TO_LOCALITY'
export const SUCCESS_FETCH_UNMAPPED_DP_TO_LOCALITY = 'SUCCESS_FETCH_UNMAPPED_DP_TO_LOCALITY'

export const REQUEST_FETCH_UNMAPPED_LOCALITIES_TO_DP = 'REQUEST_FETCH_UNMAPPED_LOCALITIES_TO_DP'
export const SUCCESS_FETCH_UNMAPPED_LOCALITIES_TO_DP = 'SUCCESS_FETCH_UNMAPPED_LOCALITIES_TO_DP'

export const REQUEST_INDEX_SEARCH_DATA = 'REQUEST_INDEX_SEARCH_DATA'
export const SUCCESS_INDEX_SEARCH_DATA = 'SUCCESS_INDEX_SEARCH_DATA'

export const REQUEST_GET_FENCE_DETAILS = 'REQUEST_GET_FENCE_DETAILS'
export const SUCCESS_GET_FENCE_DETAILS = 'SUCCESS_GET_FENCE_DETAILS'

// Geofence check
export const REQUEST_EMPTY_GEO_FENCE_CHECK_DATA = 'REQUEST_EMPTY_GEO_FENCE_CHECK_DATA'
export const SUCCESS_EMPTY_GEO_FENCE_CHECK_DATA = 'SUCCESS_EMPTY_GEO_FENCE_CHECK_DATA'
export const REQUEST_CHECK_PRIME_RETAILER = 'REQUEST_CHECK_PRIME_RETAILER'
export const REQUEST_CHECK_DELIVERY_AGENT = 'REQUEST_CHECK_DELIVERY_AGENT'
export const REQUEST_CHECK_DELIVERY_AGENT_RETAILER = 'REQUEST_CHECK_DELIVERY_AGENT_RETAILER'
export const REQUEST_CHECK_DELIVERY_TIME_FOR_LOCALITY = 'REQUEST_CHECK_DELIVERY_TIME_FOR_LOCALITY'
export const REQUEST_CHECK_ACTIVE_LOCALITY_WITHIN_CITY = 'REQUEST_CHECK_ACTIVE_LOCALITY_WITHIN_CITY'
export const REQUEST_LIST_RETAILER_OUTSIDE_LOCALITY = 'REQUEST_LIST_RETAILER_OUTSIDE_LOCALITY'
export const REQUEST_CHECK_CITY_FENCE = 'REQUEST_CHECK_CITY_FENCE'
export const SUCCESS_GEO_FENCE_CHECK = 'SUCCESS_GEO_FENCE_CHECK'

export const REQUEST_FETCH_IMAGE_ADS = 'REQUEST_FETCH_IMAGE_ADS'
export const SUCCESS_FETCH_IMAGE_ADS = 'SUCCESS_FETCH_IMAGE_ADS'

export const REQUEST_CREATE_IMAGE_AD = 'REQUEST_CREATE_IMAGE_AD'
export const SUCCESS_CREATE_IMAGE_AD = 'SUCCESS_CREATE_IMAGE_AD'

export const REQUEST_UPDATE_IMAGE_AD_STATUS = 'REQUEST_UPDATE_IMAGE_AD_STATUS'
export const SUCCESS_UPDATE_IMAGE_AD_STATUS = 'SUCCESS_UPDATE_IMAGE_AD_STATUS'


export const REQUEST_FETCH_URL_ADS = 'REQUEST_FETCH_URL_ADS'
export const SUCCESS_FETCH_URL_ADS = 'SUCCESS_FETCH_URL_ADS'

export const REQUEST_CREATE_URL_AD = 'REQUEST_CREATE_URL_AD'
export const SUCCESS_CREATE_URL_AD = 'SUCCESS_CREATE_URL_AD'

export const REQUEST_UPDATE_URL_AD_STATUS = 'REQUEST_UPDATE_URL_AD_STATUS'
export const SUCCESS_UPDATE_URL_AD_STATUS = 'SUCCESS_UPDATE_URL_AD_STATUS'

export const REQUEST_FETCH_CONSUMER_ADS = 'REQUEST_FETCH_CONSUMER_ADS'
export const SUCCESS_FETCH_CONSUMER_ADS = 'SUCCESS_FETCH_CONSUMER_ADS'

export const REQUEST_UPDATE_CONSUMER_AD_STATUS = 'REQUEST_UPDATE_CONSUMER_AD_STATUS'
export const SUCCESS_UPDATE_CONSUMER_AD_STATUS = 'SUCCESS_UPDATE_CONSUMER_AD_STATUS'

export const REQUEST_CREATE_CONSUMER_AD = 'REQUEST_CREATE_CONSUMER_AD'
export const SUCCESS_CREATE_CONSUMER_AD = 'SUCCESS_CREATE_CONSUMER_AD'

export const REQUEST_FETCH_DEEP_LINK_ADS = 'REQUEST_FETCH_DEEP_LINK_ADS'
export const SUCCESS_FETCH_DEEP_LINK_ADS = 'SUCCESS_FETCH_DEEP_LINK_ADS'

export const REQUEST_CREATE_DEEP_LINK_AD = 'REQUEST_CREATE_DEEP_LINK_AD'
export const SUCCESS_CREATE_DEEP_LINK_AD = 'SUCCESS_CREATE_DEEP_LINK_AD'

export const REQUEST_UPDATE_DEEP_LINK_AD_STATUS = 'REQUEST_UPDATE_DEEP_LINK_AD_STATUS'
export const SUCCESS_UPDATE_DEEP_LINK_AD_STATUS = 'SUCCESS_UPDATE_DEEP_LINK_AD_STATUS'

export const REQUEST_FETCH_COLLECTION_ADS = 'REQUEST_FETCH_COLLECTION_ADS'
export const SUCCESS_FETCH_COLLECTION_ADS = 'SUCCESS_FETCH_COLLECTION_ADS'

export const REQUEST_CREATE_COLLECTION_AD = 'REQUEST_CREATE_COLLECTION_AD'
export const SUCCESS_CREATE_COLLECTION_AD = 'SUCCESS_CREATE_COLLECTION_AD'

export const REQUEST_UPDATE_COLLECTION_AD_STATUS = 'REQUEST_UPDATE_COLLECTION_AD_STATUS'
export const SUCCESS_UPDATE_COLLECTION_AD_STATUS = 'SUCCESS_UPDATE_COLLECTION_AD_STATUS'

export const REQUEST_FETCH_CONTACT_NUMBERS_OF_RETAILER = 'REQUEST_FETCH_CONTACT_NUMBERS_OF_RETAILER'
export const SUCCESS_FETCH_CONTACT_NUMBERS_OF_RETAILER = 'SUCCESS_FETCH_CONTACT_NUMBERS_OF_RETAILER'

export const REQUEST_UPDATE_RETAILER_CONTACT_NUMBERS = 'REQUEST_UPDATE_RETAILER_CONTACT_NUMBERS'
export const SUCCESS_UPDATE_RETAILER_CONTACT_NUMBERS = 'SUCCESS_UPDATE_RETAILER_CONTACT_NUMBERS'

export const REQUEST_CREATE_NEW_RETAILER_CONTACT = 'REQUEST_CREATE_NEW_RETAILER_CONTACT'
export const SUCCESS_CREATE_NEW_RETAILER_CONTACT = 'SUCCESS_CREATE_NEW_RETAILER_CONTACT'

export const REQUEST_ADD_BRAND_TO_COLLECTION = 'REQUEST_ADD_BRAND_TO_COLLECTION'
export const SUCCESS_ADD_BRAND_TO_COLLECTION = 'SUCCESS_ADD_BRAND_TO_COLLECTION'

export const REQUEST_REMOVE_BRAND_FROM_COLLECTION = 'REQUEST_REMOVE_BRAND_FROM_COLLECTION'
export const SUCCESS_REMOVE_BRAND_FROM_COLLECTION = 'SUCCESS_REMOVE_BRAND_FROM_COLLECTION'

export const REQUEST_CREATE_COLLECTION = 'REQUEST_CREATE_COLLECTION'
export const SUCCESS_CREATE_COLLECTION = 'SUCCESS_CREATE_COLLECTION'

export const REQUEST_FETCH_COLLECTIONS = 'REQUEST_FETCH_COLLECTIONS'
export const SUCCESS_FETCH_COLLECTIONS = 'SUCCESS_FETCH_COLLECTIONS'

export const REQUEST_FETCH_BRANDS_IN_COLLECTION = 'REQUEST_FETCH_BRANDS_IN_COLLECTION'
export const SUCCESS_FETCH_BRANDS_IN_COLLECTION = 'SUCCESS_FETCH_BRANDS_IN_COLLECTION'

export const REQUEST_TRANSACTION_CODE = 'REQUEST_TRANSACTION_CODE'
export const SUCCESS_TRANSACTION_CODE = 'SUCCESS_TRANSACTION_CODE'

export const REQUEST_VERIFY_TRANSACTION = 'REQUEST_VERIFY_TRANSACTION'
export const SUCCESS_VERIFY_TRANSACTION = 'SUCCESS_VERIFY_TRANSACTION'

export const REQUEST_CREATE_TRANSACTION = 'REQUEST_CREATE_TRANSACTION'
export const SUCCESS_CREATE_TRANSACTION = 'SUCCESS_CREATE_TRANSACTION'

export const REQUEST_TRIGGER_SMS = 'REQUEST_TRIGGER_SMS'
export const SUCCESS_TRIGGER_SMS = 'SUCCESS_TRIGGER_SMS'

export const REQUEST_FETCH_CREDITS = 'REQUEST_FETCH_CREDITS'
export const SUCCESS_FETCH_CREDITS = 'SUCCESS_FETCH_CREDITS'

export const REQUEST_UPDATE_TRANSACTION_LIST = 'REQUEST_UPDATE_TRANSACTION_LIST'
export const SUCCESS_UPDATE_TRANSACTION_LIST = 'SUCCESS_UPDATE_TRANSACTION_LIST'

export const REQUEST_UPDATE_BRAND_LISTING_ORDER = 'REQUEST_UPDATE_BRAND_LISTING_ORDER'
export const SUCCESS_UPDATE_BRAND_LISTING_ORDER = 'SUCCESS_UPDATE_BRAND_LISTING_ORDER'

export const REQUEST_FETCH_NETBANKING_LIST = 'REQUEST_FETCH_NETBANKING_LIST'
export const SUCCESS_FETCH_NETBANKING_LIST = 'SUCCESS_FETCH_NETBANKING_LIST'

export const REQUEST_UPDATE_BANK_DETAILS = "REQUEST_UPDATE_BANK_DETAILS"
export const SUCCESS_UPDATE_BANK_DETAILS = "SUCCESS_UPDATE_BANK_DETAILS"

export const REQUEST_FETCH_USER_SPECIFIC_ADS = "REQUEST_FETCH_USER_SPECIFIC_ADS"
export const SUCCESS_FETCH_USER_SPECIFIC_ADS = "SUCCESS_FETCH_USER_SPECIFIC_ADS"

export const REQUEST_CREATE_USER_SPECIFIC_ADS = "REQUEST_CREATE_USER_SPECIFIC_ADS"
export const SUCCESS_CREATE_USER_SPECIFIC_ADS = "SUCCESS_CREATE_USER_SPECIFIC_ADS"

export const REQUEST_FETCH_USER_SPECIFIC_AD_IDS = "REQUEST_FETCH_USER_SPECIFIC_AD_IDS"
export const SUCCESS_FETCH_USER_SPECIFIC_AD_IDS = "SUCCESS_FETCH_USER_SPECIFIC_AD_IDS"

export const REQUEST_UPDATE_USER_SPECIFIC_ADS = "REQUEST_UPDATE_USER_SPECIFIC_ADS"
export const SUCCESS_UPDATE_USER_SPECIFIC_ADS = "SUCCESS_UPDATE_USER_SPECIFIC_ADS"

export const REQUEST_FETCH_USER_SPECIFIC_PROMOS = "REQUEST_FETCH_USER_SPECIFIC_PROMOS"
export const SUCCESS_FETCH_USER_SPECIFIC_PROMOS = "SUCCESS_FETCH_USER_SPECIFIC_PROMOS"

export const REQUEST_FETCH_RETAILER_SPECIFIC_PROMOS = "REQUEST_FETCH_RETAILER_SPECIFIC_PROMOS"
export const SUCCESS_FETCH_RETAILER_SPECIFIC_PROMOS = "SUCCESS_FETCH_RETAILER_SPECIFIC_PROMOS"

export const REQUEST_CREATE_RETAILER_SPECIFIC_PROMO = "REQUEST_CREATE_RETAILER_SPECIFIC_PROMO"
export const SUCCESS_CREATE_RETAILER_SPECIFIC_PROMO = "SUCCESS_CREATE_RETAILER_SPECIFIC_PROMO"

export const REQUEST_UPDATE_RETAILER_SPECIFIC_PROMO = "REQUEST_UPDATE_RETAILER_SPECIFIC_PROMO"
export const SUCCESS_UPDATE_RETAILER_SPECIFIC_PROMO = "SUCCESS_UPDATE_RETAILER_SPECIFIC_PROMO"

export const REQUEST_CREATE_USER_SPECIFIC_PROMO = "REQUEST_CREATE_USER_SPECIFIC_PROMO"
export const SUCCESS_CREATE_USER_SPECIFIC_PROMO = "SUCCESS_CREATE_USER_SPECIFIC_PROMO"

export const REQUEST_UPDATE_USER_SPECIFIC_PROMO = "REQUEST_UPDATE_USER_SPECIFIC_PROMO"
export const SUCCESS_UPDATE_USER_SPECIFIC_PROMO = "SUCCESS_UPDATE_USER_SPECIFIC_PROMO"

export const REQUEST_CREATE_CITY_SPECIFIC_PROMO = "REQUEST_CREATE_CITY_SPECIFIC_PROMO"
export const SUCCESS_CREATE_CITY_SPECIFIC_PROMO = "SUCCESS_CREATE_CITY_SPECIFIC_PROMO"

export const REQUEST_UPDATE_CITY_SPECIFIC_PROMO = "REQUEST_UPDATE_CITY_SPECIFIC_PROMO"
export const SUCCESS_UPDATE_CITY_SPECIFIC_PROMO = "SUCCESS_UPDATE_CITY_SPECIFIC_PROMO"

export const REQUEST_FETCH_CITY_SPECIFIC_PROMOS = "REQUEST_FETCH_CITY_SPECIFIC_PROMOS"
export const SUCCESS_FETCH_CITY_SPECIFIC_PROMOS = "SUCCESS_FETCH_CITY_SPECIFIC_PROMOS"

export const REQUEST_FETCH_CAMPAIGN_LIST = "REQUEST_FETCH_CAMPAIGN_LIST"
export const SUCCESS_FETCH_CAMPAIGN_LIST = "SUCCESS_FETCH_CAMPAIGN_LIST"

export const REQUEST_CREATE_CAMPAIGN = "REQUEST_CREATE_CAMPAIGN"
export const SUCCESS_CREATE_CAMPAIGN = "SUCCESS_CREATE_CAMPAIGN"

export const REQUEST_BRAND_MANAGER_LIST = "REQUEST_BRAND_MANAGER_LIST"
export const SUCCESS_BRAND_MANAGER_LIST = "SUCCESS_BRAND_MANAGER_LIST"

export const REQUEST_FETCH_CITY_POSSESSION_LIMITS = "REQUEST_FETCH_CITY_POSSESSION_LIMITS"
export const SUCCESS_FETCH_CITY_POSSESSION_LIMITS = "SUCCESS_FETCH_CITY_POSSESSION_LIMITS"

export const REQUEST_CREATE_CITY_POSSESSION_LIMIT = "REQUEST_CREATE_CITY_POSSESSION_LIMIT"
export const SUCCESS_CREATE_CITY_POSSESSION_LIMIT = "SUCCESS_CREATE_CITY_POSSESSION_LIMIT"

export const REQUEST_UPDATE_CITY_POSSESSION_LIMIT = "REQUEST_UPDATE_CITY_POSSESSION_LIMIT"
export const SUCCESS_UPDATE_CITY_POSSESSION_LIMIT = "SUCCESS_UPDATE_CITY_POSSESSION_LIMIT"

export const REQUEST_UPDATE_CAMPAIGN = "REQUEST_UPDATE_CAMPAIGN"
export const SUCCESS_UPDATE_CAMPAIGN = "SUCCESS_UPDATE_CAMPAIGN"

export const REQUEST_FETCH_CAMPAIGN_STATUS_LIST = "REQUEST_FETCH_CAMPAIGN_STATUS_LIST"
export const SUCCESS_FETCH_CAMPAIGN_STATUS_LIST = "SUCCESS_FETCH_CAMPAIGN_STATUS_LIST"

export const REQUEST_FETCH_CASHBACK_SKU_LIST = "REQUEST_FETCH_CASHBACK_SKU_LIST"
export const SUCCESS_FETCH_CASHBACK_SKU_LIST = "SUCCESS_FETCH_CASHBACK_SKU_LIST"

export const REQUEST_FETCH_SKU_PROMO_LIST = "REQUEST_FETCH_SKU_PROMO_LIST"
export const SUCCESS_FETCH_SKU_PROMO_LIST = "SUCCESS_FETCH_SKU_PROMO_LIST"

export const REQUEST_CREATE_SKU_PROMO = "REQUEST_CREATE_SKU_PROMO"
export const SUCCESS_CREATE_SKU_PROMO = "SUCCESS_CREATE_SKU_PROMO"

export const REQUEST_UPDATE_SKU_PROMO = "REQUEST_UPDATE_SKU_PROMO"
export const SUCCESS_UPDATE_SKU_PROMO = "SUCCESS_UPDATE_SKU_PROMO"

export const REQUEST_FETCH_PROMO_LIST = "REQUEST_FETCH_PROMO_LIST"
export const SUCCESS_FETCH_PROMO_LIST = "SUCCESS_FETCH_PROMO_LIST"

export const REQUEST_FETCH_STATE_LIST = "REQUEST_FETCH_STATE_LIST"
export const SUCCESS_FETCH_STATE_LIST = "SUCCESS_FETCH_STATE_LIST"

export const REQUEST_FETCH_SKU_LIST = "REQUEST_FETCH_SKU_LIST"
export const SUCCESS_FETCH_SKU_LIST = "SUCCESS_FETCH_SKU_LIST"

export const REQUEST_DOWNLOAD_REPORT = "REQUEST_DOWNLOAD_REPORT"
export const SUCCESS_DOWNLOAD_REPORT = "SUCCESS_DOWNLOAD_REPORT"

export const REQUEST_FETCH_COMPANY_LIST = "REQUEST_FETCH_COMPANY_LIST"
export const SUCCESS_FETCH_COMPANY_LIST = "SUCCESS_FETCH_COMPANY_LIST"

export const REQUEST_CREATE_COMPANY = "REQUEST_CREATE_COMPANY"
export const SUCCESS_CREATE_COMPANY = "SUCCESS_CREATE_COMPANY"

export const REQUEST_UPDATE_COMPANY = "REQUEST_UPDATE_COMPANY"
export const SUCCESS_UPDATE_COMPANY = "SUCCESS_UPDATE_COMPANY"

export const REQUEST_FETCH_MAPPED_COMPANY_LIST = "REQUEST_FETCH_MAPPED_COMPANY_LIST"
export const SUCCESS_FETCH_MAPPED_COMPANY_LIST = "SUCCESS_FETCH_MAPPED_COMPANY_LIST"

export const REQUEST_FETCH_COMPANIES = "REQUEST_FETCH_COMPANIES"
export const SUCCESS_FETCH_COMPANIES = "SUCCESS_FETCH_COMPANIES"

export const REQUEST_MAP_COMPANY_TO_BRAND = "REQUEST_MAP_COMPANY_TO_BRAND"
export const SUCCESS_MAP_COMPANY_TO_BRAND = "SUCCESS_MAP_COMPANY_TO_BRAND"

export const REQUEST_GENRE_BASED_BRAND_LIST = "REQUEST_GENRE_BASED_BRAND_LIST"
export const SUCCESS_GENRE_BASED_BRAND_LIST = "SUCCESS_GENRE_BASED_BRAND_LIST"

export const REQUEST_FETCH_GENRE_LIST = "REQUEST_FETCH_GENRE_LIST"
export const SUCCESS_FETCH_GENRE_LIST = "SUCCESS_FETCH_GENRE_LIST"

export const REQUEST_MAP_SKU_TO_PROMO = "REQUEST_MAP_SKU_TO_PROMO"
export const SUCCESS_MAP_SKU_TO_PROMO = "SUCCESS_MAP_SKU_TO_PROMO"
